import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import { VueAxios } from './utils/request'
import notify from "./utils/notification";

import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/css/bundle";
Vue.use(VueAwesomeSwiper)
Vue.prototype.$notify = notify


Vue.config.productionTip = false

// Vue.use(VueAxios)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
