import Vue from "vue";
import NotificationInit from "./../components/notificationInit";

const NotificationConstructor = Vue.extend(NotificationInit)

const instances = []

let seed = 0

const removeInstance = (instance, val) => {
    if (!instance) return
    const index = instances.findIndex(item => item.id === instance.id)

    instances.splice(index, 1)

    const len = instances.length
    if (len === 0) return
    for (let i = index; i < len; i++) {
        // console.log(removeHeight)
        instances[i].verticalOffset = parseInt(instances[i].verticalOffset) - val - 16
    }
}

const notify = (options) => {
    if (Vue.prototype.$isServer) return

    const instance = new NotificationConstructor({
        propsData: options
    })

    instance.id = `notification_${seed++}`
    instance.$mount()
    document.body.appendChild(instance.$el)
    instance.visible = true

    let verticalOffset = 16
    instances.forEach(item => {
        verticalOffset += item.$el.offsetHeight + 16
    })
    instance.verticalOffset = verticalOffset
    instance.$on('closed', (val) => {
        removeInstance(instance, val)
        document.body.removeChild(instance.$el)
        instance.$destroy()
    })

    instances.push(instance)

    return instance
}

export default notify
