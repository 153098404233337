<!--创建组件的骨架结构，包括组件中数据的声明-->
<template>
  <transition name="fade" @after-leave="afterLeave">
    <div
      class="notification"
      :style="style"
      v-show="visible"
      @mouseenter="clearTimer"
      @mouseleave="createTimer"
    >
      <span class="content">{{ content }}</span>
      <a class="btn" @click="handleClick">{{ btn }}</a>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    content: {
      //通知的具体信息
      type: String,
      required: true,
    },
    btn: {
      //用户的操作按钮提示文本，默认为“关闭”
      type: String,
      default: "关闭",
    },
    autoClose: {
      //是否启用自动关闭
      type: Boolean,
      default: true,
    },
    autoCloseTime: {
      type: Number,
      default: 3000,
      validate(value) {
        if (value < 0) return false;
      },
    },
    response: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      //verticalOffset,组件到底部的距离
      verticalOffset: 0,
      //当组件要被关闭时，记录组件之前占用的高度
      contentHeight: 0,
    };
  },
  computed: {
    style() {
      return {
        position: "fixed",
        right: "2rem",
        top: `${this.verticalOffset / 10}rem`,
      };
    },
  },
  methods: {
    createTimer() {
      //如果设置了自动关闭，则使用setTimeout来关闭
      //这个方法会在组件创建时和鼠标离开通知组件时调用
      if (this.autoClose) {
        this.timer = setTimeout(() => {
          this.contentHeight = this.$el.offsetHeight;
          this.visible = false;
        }, this.autoCloseTime);
      }
    },
    clearTimer() {
      //当鼠标处于通知上方时，如果设置了自动关闭，则清除计时器
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
    handleClick(e) {
      //处理点击事件，并且隐藏通知组件，设置contentHeight
      e.preventDefault();
      if (this.response) {
        this.response();
      }
      this.contentHeight = this.$el.offsetHeight;
      this.visible = false;
    },
    afterLeave() {
      //当transition的动画结束之后，触发closed事件
      this.$emit("closed", this.contentHeight);
    },
  },
  mounted() {
    if (this.autoClose) this.createTimer();
  },
  beforeDestroy() {
    this.clearTimer();
  },
};
</script>

<style lang="less" scoped>
.notification {
  display: inline-flex;
  background-color: #303030;
  color: rgba(255, 255, 255, 1);
  align-items: center;
  padding: 2rem;
  min-width: 28rem;
  box-shadow: 0rem 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.2),
    0rem 0.6rem 1rem 0rem rgba(0, 0, 0, 0.14),
    0rem 0.1rem 1.8rem 0rem rgba(0, 0, 0, 0.12);
  flex-wrap: wrap;
  transition: all 0.3s;
  .content {
    padding: 0;
  }
  .btn {
    color: #ff4081;
    padding-left: 2.4rem;
    margin-left: auto;
    cursor: pointer;
  }
}
</style>
