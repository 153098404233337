<template>
  <div class="app">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.setFontSize();
    window.onresize = () => {
      return (() => {
        this.setFontSize();
      })();
    };
  },
  methods: {
    // 动态设置html的大小
    setFontSize() {
      let _html = document.getElementsByTagName("html")[0];
      let minus = window.innerWidth / 1920;
      _html.style.fontSize = minus * 10 + "px";
    },
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
img {
  font-size: 0;
}
ul {
  li {
    list-style: none;
  }
}
html {
  font-size: 10px;
}
.app {
  width: 100%;
  height: 100vh;
}
.omit1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.omit2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.no-wrap {
  word-wrap: break-word;
}
</style>
